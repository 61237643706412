p {
    margin: 0;
    padding: 0;
}

.login--image-container {
    position: relative;
    width: 100%;
    overflow: hidden;

}

.login--image-container img {
    width: 100%;
    min-width: 340px;
    height: auto;
    max-height: 600px;
    min-height: 500px;
    object-fit: cover;

}

/* form styling */

.login--form-container {

    position: absolute;
    top: calc(45px + 50px);
    left: 50%;
    transform: translateX(-50%);
    width: 90%; 
    max-width: 700px;
    padding: 1rem; 
    background-color: rgba(255, 255, 255, 0.9); 
    z-index: 1; 
    text-align: center;
    box-sizing: border-box;
    font-family: 'Anonymous Pro';
}

.login--main-text {
    font-size: 24px;
    margin-bottom: 1rem;
    white-space: nowrap;
}

.bottom-text{
    font-size: 15px;
}

.bottom-text a {
    color: rgb(2, 2, 167);
    text-decoration: underline;
}

.form--button {
    background-color: #D8D8D8;
    color: black;
    border-radius: 0px;
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top: .5rem;
    padding: 0px 20px;

    font-family: 'Anonymous Pro';
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;

}

.form-error{
    color: red;
}

.text-box {
    width: 70%;
    margin-bottom: 10px;
    height: 25px;
    border: 1px black solid;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    padding-left: .5rem;

}

@media (min-width: 530px) {
    .text-box {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .login--form-container {
        width: 60%;
        padding: 2rem 0;
    }

    .login--main-text {
        font-size: 30px;
    }

    .text-box {
        width: 35%;
    }

}