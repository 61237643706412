

@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100..900&display=swap');

p{
    margin: 0px;
}

/* border and text styles */

.arrow-icon {
    white-space: nowrap;
}
.infocard--solid-border {
    border-bottom: 1px solid #D8D8D8;
}

.infocard--drop-shadow {
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 5px;

}

/* .infocard--dashed-border {
    border-bottom: 1px dashed #D8D8D8;
} */
.infocard--main-text {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: black;
    white-space: nowrap;

}
.infocard--sub-text, .infocard--pre-text {
    font-family: 'Inter';
    font-size: 12px;
    color: #303030;
}

.airline-img-container {
    display: flex;
    align-items: center;
    gap: 3px;
}

.airline-img-small, .airline-img-large{
    display: inline-block;
    max-width: 20px;
    object-fit: contain;
}

.price-text {
    font-family: 'IBM Plex Mono';
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.infocard--leg {
    padding: 0px 10px;
    font-family: 'IBM Plex Mono';
    color: black;
    font-size: 16px;
    font-weight: 500;

}

/* main section styling */

.infocard--container {
    background-color: white;
    margin-bottom: 5px;
    padding: .35rem .2rem;
    border-radius: 5px;
    box-shadow: 2px 2px 2px  rgba(0, 0, 0, 0.3);
}

.logoUrl {
    display: block;
    width: 50px !important;
    height: 50px;
    border: 2px solid red;
}

.infocard-col-1 {
    display: flex;
    align-items: center;
    /* border: 5px solid purple; */
}

/* toggle section */

.leg-container {
    display: flex;
    justify-content: space-around;
    /* border: 2px solid red; */
    padding: .25rem 0;
    align-items: center;
    /* gap: 10px; */

}

.col-1 {
    flex: 1;
    /* margin-right: 1rem; */
    /* border: 2px solid red; */
}

.col-2 {
    flex: 2;
    /* margin-right: 1rem; */
    /* border: 2px solid green; */
}

.col-3 {
    flex: 1;
    /* margin-right: 1rem; */
    /* border: 2px solid blue; */
}


/* 768px screen size */


.infocard--header {
    font-family: 'Inter';
    font-size: 16px;
    margin-left: 16.2%;
    display: none;
}

.infocard--numStops {
    display: none;

}
.infocard--pre-text {
    display: none;
}

.infocard--airline-column {
    flex: 1;
    /* border: 2px solid salmon; */
    display: none;
    /* border: 2px solid fuchsia; */
}

.col-1 {
    /* border: 2px solid yellow; */
    flex: 0.5;
}
.col-2 {
    /* border: 2px solid blue; */
    flex: 1;
}

.col-3 {
    /* border: 2px solid salmon; */
    flex: 0.5
}

.col-4 {
    flex: 0.5;
    /* border: 2px solid purple; */
    display: none;
}


@media(min-width: 768px) {
    .airline-img-small {
        display: none;
    }
    .infocard--sub-text {
        display: none;
    }

    .infocard--header {
        display: block;
    }

    .infocard--numStops {
        display: block;
    }

    .infocard--pre-text {
        display: block;
    
    }
    .infocard--airline-column {
        display: block;
    }

    .col-4 {
        display: block;
    
    }
}
