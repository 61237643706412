

/* navbar */
a {
    text-decoration: none;
    color: white;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    min-width: 340px;
}

.navbar-overlay {
    position: absolute;
    width: 100%;
    height: 45px;
    left: 0px;
    top: 0px;
    background: #D9D9D9;
    background-color: rgba(190, 190, 190, 0.3);
    z-index: 1;
    padding: .5rem 0;
}

.navbar-items {
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1100px;
}

.navbar-logo {
    margin-right: auto;
    font-size: 28px;
    font-weight: 500;
    color: white;
}

.navbar-links {
    display: none;
} 

.hamburger-menu {
    cursor: pointer;
    color: white;
    font-size: 24px;
}


/* plane background image */
.image-container{
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}

.image-container img {
    width: 100%;
    max-height: 500px;
    min-height: 350px;
    object-fit: cover;

}


/* dark image overlay and header text */
.text-overlay {
    display: none;
}

/* tabs */
.tab-wrapper{
    position: absolute;
    bottom: 0;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1040px;
    width: 100%; /* Ensure it doesn't exceed the max-width */
    box-sizing: border-box; /* Include padding and border in the width */
}
.tab {
    padding: 7px 0px;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;

}
.tab-1 {
    background-color: white;
    color: black;
    border-top: 5px solid #1571AC;
    border-bottom: 2px solid #eee;
    grid-column: span 2;
}
/* .tab-2 {
    background-color: rgba(0, 0, 0, 0.5); 
    color: #FFFFFF;
    grid-column: span 2;
} */
.tab-background{
    display: none;
}


@media (min-width: 768px){

    .navbar-links {
        display: flex;
        font-family: "Merriweather", serif;
        font-weight: 700;
        color: white;
        z-index: 2;
    }
    .navbar-links :not(:last-child){
        padding-right: 4em; 
    }
    .hamburger-menu {
        display: none;
    }

    .tab-wrapper {
        max-width: 1100px;
        width: 100%;

    }

    .tab-1 {
        grid-column: span 1;
        background-color: #F0F0F0;
    }
    /* .tab-2 {
        grid-column: span 1;
    } */
    .tab-background {
        display: block;
        grid-column: span 3;
        background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */

    }
  
}