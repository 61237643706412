


.month-container {
    margin-top: .75rem;
}

.month-label {
    margin-right: 1.25rem;
    margin-top: 2em;
}