
.alert-button {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    /* border: 1px solid #636363; */
    background-color: #1A5D92;
    font-size: 14px;
    color: white;
    cursor: pointer;
    border-radius: 0px;
    font-family: 'IBM Plex Mono';

    font-weight: 500;
    letter-spacing: 0.09em;
    text-transform: uppercase;
}

.alert-fielderror{
    color:red;
}

.success-notif {
    display: flex;
    align-items: center;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.checkmark {
    font-size: 20px;
    margin-right: 10px;
}

.setalert-login{
    margin-top: 15px;
    color: red;
}