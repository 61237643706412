.alerts-setprice{
    margin-top: 1.25em;
}

.alerts-notify{
    margin-top: .312rem;
}

.card--container{
    /* border: 2px solid red; */
    /* display: grid; */
    height: 100vh;
    /* blue-to-white gradient */
    background: linear-gradient(186.97deg, #ffffff 40.78%, #80afc2, hsl(212, 85%, 34%));
    justify-content: center;

}

.card{
    padding: 1em .55em;
    /* background-color: rgba(0, 0, 0, 0.1); Correct rgba syntax */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.button-options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    font-size: 14px;
    font-family: "Inter";
    margin-bottom: 20px;
}

.option {
    grid-column: span 1;
    background-color: #ECECEC;
    border: 1px solid rgba(177, 164, 164, 0.671);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    white-space: nowrap;
    position: relative;
}

.alerts--dropdown {
    background-color: black; /* Background color for visibility */
    border: 1px solid #ccc; /* Border for clarity */
    grid-column: span 2;
    padding: 10px; /* Add some padding inside the dropdown */
    z-index: 2; /* Ensure it’s on top of other elements */

}

.alerts--ul {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.alerts--ul li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    list-style: none;
}

.alerts--ul li:hover {
    background-color: #f0f0f0;
}




.toggle {
    cursor: pointer;
}

@media (min-width: 768px){
    .card{
        /* border: 2px solid green; */
        background-color: #F0F0F0;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        box-sizing:border-box;
        padding: 1.5em 3.5em 90px 3.5em;
    }

    .option {
        background-color: white;
    }
}

