.fs--best-flights {
    font-family: 'IBM Plex Mono';
    color: black;
    font-size: 20px;
    font-weight: 400;
}

.form-container {
    /* border: 3px solid green; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
}

.wrapper { 
    position: relative;  /* wrapper added to position icons absolutely within input */
    width: 100%;
    /* margin-bottom: 6px; */
    /* border: 1px solid yellow; */
}
.flight-details {
    height: 70vh;
    overflow-y: auto;
    width: 100%;
    /* border: 2px solid red; */
}

.react-datepicker-wrapper {
    width: 100%;
}

.input-box, .react-datepicker__input-container input {
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    padding: 7px 30px; 
    border: 1px solid #636363;
    font-size: 16px;
    border-radius: 0px;
    font-family: 'Inter';

}


/* Make the last two inputs take up the full row */
.centered-input {
    /* border: 2px solid red; */
    grid-column: span 2;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
}

.icon {
    position: absolute;
    left: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: black; /* Adjust icon color */
  }

  .checkbox-label {
    font-family: 'IBM Plex Mono';
    margin-bottom: 20px;
    font-weight: 500;
    color: #292929;
    grid-column: span 2;

  }

/* dropdown container */
.flightsearch--ul {
    position: absolute;
    top: calc(100% + 5px); /* Places the dropdown just below the input box with a small gap */
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px; /* Limits the height of the dropdown */
    overflow-y: auto; /* Adds scroll if the list is too long */
    padding: 0;
    margin: 0; /* Removes default margin */
    z-index: 1000; /* Ensures the dropdown appears above other elements */
    list-style: none; /* Removes default bullet points */
}

.flightsearch--ul li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.flightsearch--ul li:hover {
    background-color: #f0f0f0;
}


@media (min-width: 768px){

    .form-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
    .centered-input {
        grid-column: span 1;
        width: 100%;
    }

    .horizontal-buttons {
        display: flex;
        gap: 5px;
    }

    .checkbox-label {
        grid-row: 3;
        grid-column: span 3;
    }
}
